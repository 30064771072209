@tailwind base;
@tailwind components;
@tailwind utilities;

.body-text p {
  padding-bottom: 1em;
  min-height: 1px;
}

.body-text ul {
  list-style-type: disc;
  padding-left: 40px;
}

.body-text ol {
  list-style-type: decimal;
  padding-left: 40px;
}

.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 19rem;
  padding: 1.6rem 6% 0;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  flex-direction: row;
  height: auto;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}

.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}

.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(249, 249, 249, 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}

.embla__slide__number>span {
  color: rgb(47, 112, 193);
  background-image: #ffffff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.embla__dot,
.embla__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}

.embla__dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.embla__dot {
  width: 7px;
  height: 3rem;
  border-radius: 7px;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.embla__dot:after {
  background: hsla(0, 0%, 100%, .5);
  border-radius: 0.2rem;
  width: 100%;
  height: 0.3rem;
  content: '';
}

.embla__dot--selected:after {
  background: #ffffff;
}

.embla__button {
  z-index: 1;
  color: hsla(0, 0%, 100%, .5);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 325px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 4rem;
  height: 4rem;
}

.embla__button_video {
  z-index: 1;
  color: hsla(0, 0%, 100%, .5);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 290px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 4rem;
  height: 4rem;
}

.embla__button--prev {
  left: 1.6rem;
  padding-right: 20px;
}

.embla__button--next {
  right: 1.6rem;
  padding-left: 20px;
}

.embla__button--prev_video {
  padding-right: 20px;
}

.embla__button--next_video {
  padding-left: 20px;
}

.embla__button:disabled {
  opacity: 0.3;
}

.embla__button__svg {
  width: 65%;
  height: 28px;
  fill: #ffffff;
}

.embla-thumbs {
  --thumbs-slide-spacing: 0.8rem;
  --thumbs-slide-height: 9rem;
  margin-top: var(--thumbs-slide-spacing);
}

.embla-thumbs__viewport {
  overflow: hidden;
}

.embla-thumbs__container {
  display: flex;
  flex-direction: row;
  margin-left: calc(var(--thumbs-slide-spacing) * -1);
  justify-content: center;
}

.embla-thumbs__slide {
  flex: 0 0 28%;
  min-width: 0;
  padding-left: var(--thumbs-slide-spacing);
  position: relative;
}

@media (min-width: 576px) {
  .embla-thumbs__slide {
    flex: 0 0 18%;
  }
}

.embla-thumbs__slide__button {
  opacity: 1;
  touch-action: manipulation;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
}

.embla-thumbs__slide--selected .embla-thumbs__slide__button .button-cover {
  -webkit-appearance: none;
  /* background: rgba(0,0,0,.6); */
  opacity: 100 !important;
}

.embla-thumbs__slide__img {
  display: block;
  height: auto;
  width: 100%;
  object-fit: cover;
}

.embla-thumbs__slide__number {
  width: 3rem;
  height: 3rem;
  z-index: 1;
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 3rem;
  font-weight: 500;
  text-align: center;
  pointer-events: none;
}

.embla-thumbs__slide__number>span {
  color: var(--brand-primary);
  background-image: linear-gradient(45deg,
      var(--brand-primary),
      var(--brand-secondary));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.4rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Josefin Sans', Helvetica, Arial, Lucida, sans-serif;
}